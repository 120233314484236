@import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap');
.intro-container {
  z-index: -1;
  position: relative;
  margin-bottom: 5%;
  padding-top: 10rem;
}
.heading{
  font-family: "Russo One", sans-serif;
}
.bg-container {

  top: 0;
  left: 0;
  width: 100%;
  z-index: -1;
  position: absolute;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-image: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.4)), url('../assets/factory.jpg');
}
  tr, td,th {
  border: 1px solid black;
}
table{
  padding: 5px;
}